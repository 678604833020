.menu {
    position: absolute;
    inset: 0;
    z-index: 5;
}

.menu .v-flex {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.menu .h-flex {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    height: 100%;
}

.menu .header {
    height: 64px;
    text-align: center;
    font-size: 26pt;
}

.menu .button {
    margin-top: 12px;
}