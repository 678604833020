.register-elements {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.h-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.register-elements .input {
    margin-bottom: 8px;
}


.back-link {
    margin-top: 16px;
    cursor: pointer;
    color: #ffffff;
}

.back-link:hover {
    color: rgba(52, 152, 219, 0.8);
}