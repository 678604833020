input {
    height: 36px;
    -webkit-appearance: none;
    border: none;
    background: rgba(52, 152, 219, 0.3);
    color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 13pt;
}

input:before {
    position: absolute;
    content: "";
    inset: 2px;
    border: 1px solid rgba(52, 152, 219, 0.8);
    border-radius: 2px;
}


button {
    all: unset;
    cursor: pointer;
    position: relative;
    width: 196px;
    height: 48px;
    text-align: center;
    background: rgba(52, 152, 219, 0.5);
    border-radius: 4px;
    text-transform: uppercase;
}

button:before {
    position: absolute;
    content: "";
    inset: 2px;
    border: 1px solid rgba(52, 152, 219, 0.8);
    border-radius: 2px;
}

button:hover {
    background: rgba(52, 152, 219, 0.6)
}

button:disabled {
    background: rgba(142, 142, 142, 0.5);
    cursor: default;
}
button:disabled:before {
    border: 1px solid rgba(142, 142, 142, 0.5);
    cursor: default;
}