html, body {
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #000;
}

div {
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background {
    color: #fff;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.background.gradient {
    background-image: linear-gradient(to top, #1e272e, #284450, #485460);
}

.background.mono {
    background: #000;
}

.game-field {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: block;
    position: relative;
    /*
    background:
            linear-gradient(-90deg, rgba(0,0,0,.05) 1px, transparent 1px),
            linear-gradient(rgba(0,0,0,.05) 1px, transparent 1px),
            linear-gradient(-90deg, rgba(0, 0, 0, .04) 1px, transparent 1px),
            linear-gradient(rgba(0,0,0,.04) 1px, transparent 1px),
            linear-gradient(transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
            linear-gradient(-90deg, #aaa 1px, transparent 1px),
            linear-gradient(-90deg, transparent 3px, #f2f2f2 3px, #f2f2f2 78px, transparent 78px),
            linear-gradient(#aaa 1px, transparent 1px),
            #f2f2f2;
    background-size:
            4px 4px,
            4px 4px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px;

     */
    background:
            linear-gradient(-90deg, rgba(255,255,255,.05) 1px, transparent 1px),
            linear-gradient(rgba(255,255,255,.05) 1px, transparent 1px),
            linear-gradient(-90deg, rgba(255, 255, 255, .04) 1px, transparent 1px),
            linear-gradient(rgba(255,255,255,.04) 1px, transparent 1px),
            linear-gradient(transparent 3px, #24243e 3px, #24243e 78px, transparent 78px),
            linear-gradient(-90deg, #302b63 1px, transparent 1px),
            linear-gradient(-90deg, transparent 3px, #24243e 3px, #24243e 78px, transparent 78px),
            linear-gradient(#302b63 1px, transparent 1px),
            #24243e;
    background-size:
            4px 4px,
            4px 4px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px,
            80px 80px;

}

.game-field .shadow {
    z-index: 2;
    /*
    -webkit-box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.8);
    box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.8);
     */
    position: absolute;
    top: -50px;
    bottom: -50px;
    width: 100%;
    display: block;
}

.game-field-ui {
    position: absolute;
    width: 0;
    height: 0;
    z-index: 2;
}

.game-field-ui .exit-btn {
    position: fixed;
    right: 8px;
    top: 8px;
    height: 36px;
    width: 36px;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
}

.game-field-ui .exit-btn:hover {
    background: rgba(255,255,255,0.1);
}



.game-field-wrap {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: relative;
}

.game-field-wrap.pointer {
    cursor: pointer;
}

.plank {
    position: absolute;
    background: #ecf0f1;
    border: 2px solid #ecf0f1;
    transform: translate(-50%, 50%);
    /*
    -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.3);
     */
}
.plank.danger {
    background: repeating-linear-gradient(
            45deg,
            #ecf0f1,
            #ecf0f1 10px,
            #ff7f1f 10px,
            #ff7f1f 15px
    );
}
.plank.more-danger {
    background: repeating-linear-gradient(
            45deg,
            #850606,
            #850606 10px,
            #ff7f1f 10px,
            #ff7f1f 15px
    );
}
.plank.broken {
    background: rgba(133, 6, 6, 0.3);
    border: 2px solid rgba(133, 6, 6, 0.5);
}

.plank.is-hit {
    border-color: #bdbfc2;
}

.projectile-position {
    position: absolute;
    overflow: visible;
    transform: translate(-50%, 50%);
    z-index: 3;
}

.projectile {
    width: 100%;
    height: 100%;
}

.camera-move {
    width: 100%;
    position: absolute;
    z-index: 1;
    overflow: visible;
}

.camera-move .floor {
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: -2px;
    right: -2px;
    background: #ecf0f1;
    /*
    -webkit-box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3);
     box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.3);
      */
    box-sizing: border-box;
}
/*
.camera-move .floor::before {
    content: 'Lets go!';
    text-align: center;
    display: block;
    margin: 5% auto;
    font-size: 26pt;
    color: #ff3f34;
}
 */

.impact {
    position: absolute;
    transform: translate(-50%, 50%);
    background: radial-gradient(#ffdb54 0%, #f33500 50%, #000000 100%);
    border-radius: 100%;
}

.impact.infront {
    z-index: 5;
}

.impact.behind {
    z-index: 0;
}