.auth-user-tab {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.h-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.auth-user-tab .user-tab-head {
    flex-grow: 0;
}


.friend-list {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.friend-list .friend-list-header {
    margin: 4px 8px;
}

.friend-list .friend-list-entry {
    height: 48px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    margin: 4px 8px;
    display: flex;
    flex-direction: row;
}

.friend-list .friend-list-entry .name {
    font-size: 14pt;
    flex-grow: 1;
    padding: 8px 16px;
}

.friend-list .friend-list-entry .status {
    width: 80px;
    flex-grow: 0;
    padding-top: 10px;
    padding-right: 16px;
    text-align: right;
}

.friend-list .friend-list-entry .status.online {
    color: #00ff00;
}

.friend-list .friend-list-entry .status.afk {
    color: #ffff00;
}

.friend-list .friend-list-entry .status.offline {
    color: #888888;
}

.logout-wrap {
    height: 64px;
    width: 100%;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.logout-wrap .logout {
    background-color: rgba(255, 255, 255, 0.0);
}
.logout-wrap .logout:hover {
    background-color: rgba(200, 20, 0, 0.5);
}