.login-elements {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.h-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.login-elements .input {
    margin-bottom: 8px;
}


.register-link {
    margin-top: 16px;
    cursor: pointer;
    color: #ffffff;
    text-align: center;
}

.error {
    margin-top: 16px;
    color: #cc0000;
    text-align: center;
}

.register-link:hover {
    color: rgba(52, 152, 219, 0.8);
}