.user-tab-wrap-flex {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.user-tab-wrap-flex .user-tab {
    flex-shrink: 0;
    flex-grow: 0;
}

.user-tab-wrap-flex .content-mount {
    flex-grow: 1;
    flex-shrink: 1;
}

.user-tab-wrap-stacked {

}

@keyframes slideIn {
    from {
        left: -100%
    }
    to {
        left: 0;
        animation-play-state: paused;
    }
}


@keyframes slideOut {
    from {
        left: 0
    }
    to {
        left: -100%;
        animation-play-state: paused;
    }
}

.user-tab-wrap-stacked .user-tab.opened {
    animation: slideIn 0.15s ease-in-out;
    left: 0;
}
.user-tab-wrap-stacked .user-tab.closed {
    animation: slideOut 0.15s ease-in-out;
    left: -100%;
}
.user-tab-wrap-stacked .user-tab {
    position: fixed;
    width: 100%;
    bottom: 0;
    top: 0;
    left: -100%;
}

.user-tab-wrap-stacked .content-mount {
    position: fixed;
    inset: 0
}

.user-tab-open-icon {
    position: absolute;
    padding: 16px;
    z-index: 10;
    cursor: pointer;
}

.user-tab-open-icon .icon-text {
    position: absolute;
    right: 0;
    transform: translateX(100%);
    font-size: 15pt;
}

.user-tab-open-icon svg {
    position: relative;
    z-index: 1;
    font-size: 24pt;
    color: rgba(255, 255, 255, 0.8);
}

.user-tab-open-icon:hover > svg {
    color: rgba(52, 152, 219, 0.8);
}

.user-tab-open-icon:hover > .icon-text {
    color: rgba(52, 152, 219, 0.8);
}

.user-tab {
    z-index: 100;
    display: block;
    background: #292943;
    border-right: 1px solid rgba(255, 255, 255, 0.08);
}

.content-mount {
}

.user-tab-head {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.user-tab-head .icon{
    cursor: pointer;
    padding: 16px;
    flex-grow: 0;
    flex-shrink: 0;
}
.user-tab-head .icon:hover {
    color: rgba(52, 152, 219, 0.8);
}
.user-tab-head .title{
    padding: 8px 16px 16px;
    font-size: 20pt;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
}
